/* General styles to apply across the app */
body, html {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center; /* This centers everything vertically */
    background-color: #1B263B; /* Background color for the entire viewport */
    font-family: 'Nova Round', cursive;
}

/* Wrapper for the entire app to handle the layout */
.appContainer {
    display: flex;
    justify-content: center;  /* Horizontally centers the content */
    align-items: center;      /* Vertically centers the content */
    height: 100vh;            /* Full viewport height */
    width: 100vw;             /* Full viewport width */
    background-color: #1B263B; /* Background color for the entire app */
}

/* Centered container to hold both sidebar and main content */
.centeredContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;  /* Horizontal spacing between sidebar and content */
    align-items: center;             /* Vertically center the content */
    background-color: #2B3A4B;       /* Background color for the container */
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Optional shadow for depth */
    width: 80vw;                      /* Use 80% of the viewport width */
    max-width: 1200px;                /* Set a maximum width to prevent overflow */
    margin: auto;
}

/* Sidebar styles */
.sidebar {
    background: #6480A1;
    border-radius: 15px;
    width: 300px;             /* Sidebar fixed width */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;      /* Center the content horizontally */
    height: auto;             /* Sidebar height auto to prevent overflow */
    margin-right: 20px;       /* Space between sidebar and main content */
    overflow: hidden;         /* Remove scrollbar */
    max-height: 100%;         /* Ensure it fits within the container */
}

/* Main content styles */
.mainContent {
    background-color: #1B263B;
    border-radius: 25px;
    padding: 30px;
    width: 700px;             /* Main content fixed width */
    max-height: calc(100vh - 160px); /* Adjust height to ensure it fits well */
    overflow-y: auto;         /* Scroll content if needed */
}

/* General button styles */
.button {
    background-color: #0E748B; /* Vibrant teal for buttons */
    color: white; /* White text for readability */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    background-color: #14A0A0; /* A lighter shade for hover effect */
}

/* List items */
.listItem {
    background-color: #415A77; /* Muted blue for items */
    color: white;
    margin-bottom: 10px; /* Adds space between the items */
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.listItemButton {
    width: 100%;               /* Full width of the parent container */
    justify-content: center;    /* Center the button text */
    text-align: center;         /* Center the button text */
}
/* Paper component */
.Paper {
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    background: #e3f2fd;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    max-height: calc((100vh - 80px) / 3); /* Divide the available height by the number of Papers */
    overflow-y: auto;
}

/* Responsive design adjustments for mobile */
@media (max-width: 768px) {
    .centeredContainer {
        flex-direction: column;  /* Stack sidebar and main content vertically */
        width: 90%;              /* Ensure the container doesn't take up full width */
    }

    .sidebar, .mainContent {
        width: 100%;             /* Full width on mobile */
        margin: 20px 0;          /* Add space between sidebar and content */
    }
}
